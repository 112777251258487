<!--
 * @Author: jiang
 * @Date: 2021-07-18 09:51:06
 * @Description: 项目详情
-->
<template>
  <el-scrollbar
    y
    view-style="padding: 0 15px 15px;"
  >
    <div class="g-bg g-p-20 info-box">
      <div class="name g-text-ellipsis">{{ infoData.name }}</div>
      <div class="ff">
        <el-tag
          class="g-mr-10"
          type="primary"
          effect="plain"
        >{{ infoData.build_department }}</el-tag>
        <el-tag
          class="g-mr-10"
          type="primary"
          effect="plain"
        >{{ infoData.industry }}</el-tag>
        <el-tag
          class="g-mr-10"
          type="primary"
          effect="plain"
        >{{ infoData.year }}</el-tag>
      </div>
      <div class="status-box">
        <div class="status">
          <span>{{ infoData.progress_state + '' | dict('projectWarningStatusDict') }}</span>
          <span v-if="infoData.comment">({{ infoData.comment }})</span>
        </div>
        <div
          class="fill"
          v-if="infoData.fill_in_state === '0'"
        >未完成本月调度数据填报'</div>
      </div>
    </div>

    <div class="g-mt-15 g-bg">
      <div class="info">
        <div style="margin-right: 50px;">
          中央投资资金支出比例:
          <span class="num">{{ infoData.center_invest_pay_scale }}%</span>
        </div>
        <div>
          第一次投资计划下达年度:
          <span class="num">{{ infoData.first_invest_year }}</span>
        </div>
      </div>
      <div class="chart-box">
        <div class="chart-item">
          <div>
            <chart-view :option="chartOption1"></chart-view>
          </div>
        </div>
        <div class="chart-item">
          <div>
            <chart-view :option="chartOption2"></chart-view>
          </div>
        </div>
        <div class="chart-item">
          <div>
            <chart-view :option="chartOption3"></chart-view>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
import { getProject } from '@/api/project'
import ChartView from './components/chart'

export default {
  components: {
    ChartView,
  },
  props: {
    id: {},
  },
  data() {
    return {
      infoData: {},
      chartOption1: {},
      chartOption2: {},
      chartOption3: {},
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getProject(this.id).then(res => {
        this.infoData = res
        this.chartOption1 = {
          title: {
            text: '下达投资情况(万元)',
            subtext: `总投资：${res.release_invest_count}`,
          },
          series: [
            {
              data: [
                ['中央投资', res.release_invest_center],
                ['地方配套', res.release_invest_local],
                ['自筹资金', res.release_invest_self],
              ],
            },
          ],
        }
        this.chartOption2 = {
          title: {
            text: '已到位投资(万元)',
            subtext: `总投资：${res.place_invest_count}`,
          },
          series: [
            {
              data: [
                ['中央投资', res.place_invest_center],
                ['地方配套', res.place_invest_local],
                ['自筹资金', res.place_invest_self],
              ],
            },
          ],
        }
        this.chartOption3 = {
          title: {
            text: '已完成投资(万元)',
            subtext: `总投资：${res.complete_invest_count}`,
          },
          series: [
            {
              data: [
                ['中央投资', res.complete_invest_center],
                ['地方配套', res.complete_invest_local],
                ['自筹资金', res.complete_invest_self],
              ],
            },
          ],
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.info-box {
  padding: 20px;

  .name {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #2b2d42;
  }

  .status-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .status {
      font-size: 12px;
      color: #fa8c16;
    }

    .fill {
      font-size: 12px;
      color: #747e8f;
    }
  }
}

.info {
  display: flex;
  padding: 15px;
  font-size: 18px;
  line-height: 50px;
  color: #888;

  .num {
    color: #333;
  }
}

.chart-box {
  display: flex;

  .chart-item {
    flex: 1;
    min-width: 0;
    aspect-ratio: '1:1';
  }
}
</style>
